import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ToastService } from '../../../@core/services/toast.service';
import { ContractService } from '../../../contract-management/services/contracts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DocumentTypeService } from '../../../contract-management/services/document-type.service';
import { IDocumentType } from '../../../contract-management/models/settings.model';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { FILE_EXTENSION, FILE_TYPE, IFile } from '../../../contract-management/components/settings/repository/repository.types';
import { FullNamePipe } from "../../pipes/fullName.pipe";
import { UserAvatarPipe } from "../../pipes/user-avatar-pipe";
import { first } from 'lodash';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from "../../pipes/truncate.pipe";

@Component({
  selector: 'app-ai-assistant',
  standalone: true,
  imports: [DialogModule, FormsModule, CommonModule, ButtonModule, DropdownModule, ProgressBarModule, FullNamePipe, UserAvatarPipe, TruncatePipe],
  templateUrl: './ai-assistant.component.html',
  styleUrl: './ai-assistant.component.scss'
})
export class AiAssistantComponent implements OnInit {

  @Input() visible: boolean = false
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onDialogClose = new EventEmitter<void>();
  @Output() onGenerate = new EventEmitter<string>();

  docId!: string

  content: string = ''
  response: string = ''
  loading: boolean = false
  wordCount: number = 0
  wordLimit: number = 500
  docTypeLoading: boolean = false
  docTypes: IDocumentType[] = []
  selectedDocType: IDocumentType | null = null
  aiAssistant: {
    open: boolean,
    content: string,
    response: string,
    stage: string,
    loading: boolean
    loadingPercentage: number
  } = {
      open: false, content: '', response: '', stage: 'Igniting creativity – Starting with AI', loading: false, loadingPercentage: 0
    };

  public promptQuestions = [
    'Create a contract for general contractor services',
    'Draft a construction project subcontractor agreement',
    'Write a contract for a construction site inspection service'
  ]

  public templateSuggestions: IFile[] = []

  templateSuggestionLoading: boolean = false
  selectedTemplate: IFile | null = null
  fromScratch: boolean = false
  templateSuggestionPage = false

  constructor(private toastService: ToastService, private contractService: ContractService, private router: Router, private activatedRoute: ActivatedRoute, private documentTypeService: DocumentTypeService) {
    this.docId = this.activatedRoute.snapshot.queryParams['documentId']
  }

  ngOnInit() {
    this.getDocTypes()
  }

  openAIAssistant() {
    this.visible = true;
  }

  cancel() {
    this.resetAiAssistant();
    this.templateSuggestionPage = false
    this.visibleChange.emit(this.visible);
    this.onDialogClose.emit();
  }

  resetAiAssistant() {
    this.content = '';
    this.response = '';
    this.loading = false;
    this.aiAssistant.loading = false;
    this.aiAssistant.stage = 'Igniting creativity - Starting with AI!';
    this.aiAssistant.loadingPercentage = 0;
  }

  getDocTypes() {
    this.docTypeLoading = true
    this.documentTypeService.getDocTypes().subscribe(res => {
      if (res && res.success) {
        this.docTypeLoading = false
        this.docTypes = res.data || []
      }
      else
        this.docTypeLoading = false
    })
  }
  selectTemplate(template: IFile) {
    this.selectedTemplate = template;
  }

  getRelatedTemplates() {
    this.templateSuggestionPage = true;
    this.aiAssistant.stage = 'Setting the foundation – Choose a template to start.';
    this.templateSuggestionLoading = true;
    this.contractService.getRelatedTemplates(this.content, this.selectedDocType?._id || '').subscribe(
      {
        next: res => {
          if (res) {
            this.templateSuggestions = res.data;
            this.templateSuggestionLoading = false;
          }
        },
        error: err => {
          console.log(err)
          this.templateSuggestionLoading = false;
        }
      });
  }

  callAIAssistant() {
    this.aiAssistant.loading = true;

    const stages = [
      'Igniting creativity – Starting with AI!',
      'Setting the foundation – Preparing your document.',
      'AI is on it – your document is taking shape!',
      'Enhancing details – Refining the context.',
      'Reviewing for consistency – Preparing the final version.',
      'Finalizing details - almost there!'
    ];

    let stageIndex = 0;

    const interval = setInterval(() => {
      if (stageIndex < stages.length) {
        this.aiAssistant.stage = stages[stageIndex];
        this.aiAssistant.loadingPercentage = Math.min((stageIndex + 1) * 15, 95);
        stageIndex++;
      } else {
        clearInterval(interval);
      }
    }, 3000);

    this.toastService.info('The document is being generated.');
    this.contractService.generateContractWithAi(this.content, this.selectedDocType?._id ?? '', this.docId, this.selectedTemplate?._id).subscribe((res) => {
      if (res.success) {
      } else {
        clearInterval(interval);
        this.aiAssistant.loading = false;
        this.toastService.error('Error generating document')
      }
    })
    // this.toastService.info('Generating your document, please wait...');
    // this.contractService.generateContractWithAi(this.content, this.selectedDocType?._id ?? '', this.docId, this.selectedTemplate?._id).subscribe({
    //   next: res => {
    //     clearInterval(interval);
    //     this.aiAssistant.loading = false;

    //     this.aiAssistant.stage = 'Final touches applied – your document is ready!';
    //     this.aiAssistant.loadingPercentage = 100;
    //     if (this.docId) {
    //       const url = this.router.createUrlTree(['/edit-document'], {
    //         queryParams: {
    //           documentId: res.data
    //         }
    //       }).toString();
    //       this.visible = false;
    //       this.cancel();
    //       window.open(url, '_blank')
    //     }
    //     else {
    //       this.visible = false;
    //       this.cancel();
    //       this.router.navigate(['/edit-document'], { queryParams: { documentId: res.data } });
    //     }
    //   },
    //   error: _ => {
    //     clearInterval(interval);
    //     this.aiAssistant.loading = false;
    //   }
    // });
  }
  checkWordLimit() {
    this.wordCount = this.getWordCount(this.content);

    if (this.wordCount > this.wordLimit) {
      const wordsArray = this.content.split(/\s+/).slice(0, this.wordLimit);
      this.content = wordsArray.join(' ');
    }
  }

  openDocument(documentId: string) {
    window.location.href = `/edit-document?documentId=${documentId}`;
  }

  getWordCount(content: string): number {
    if (!content) return 0;
    return content.trim().split(/\s+/).length;
  }

  sendPrompt(prompt: string) {
    this.content = prompt
    this.checkWordLimit()
  }
}
