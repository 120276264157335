import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../../environments/environment.dev';
import { ExtractedEntity } from '../../contract-management/components/settings/repository/components/directory/directory.component';
import { IGetContractTagMappings } from '../../contract-management/services/contract-tag.service';
import { IGetContractChecklist } from '../../contract-management/services/contract-checklist-item.service';

//https://socket.io/docs/v4/typescript/#types-for-the-client

export enum SOCKET_EVENTS {
    REFRESH_IN_PROGRESS = 'REFRESH_IN_PROGRESS',
    REFRESH_DONE = 'REFRESH_DONE',
    REPO_FILES_STATUS = 'REPO_FILES_STATUS',
    REPO_TAG_EXTRACTED = 'REPO_TAG_EXTRACTED',
    CONTRACT_TAG_MAPPING = 'CONTRACT_TAG_MAPPING',
    SUMMARY_GENERATION_COMPLETED = 'SUMMARY_GENERATION_COMPLETED',
    CHECKLIST_ITEMS_EXTRACTED = 'CHECKLIST_ITEMS_EXTRACTED',
    SIGNATORIES_UPDATED = 'SIGNATORIES_UPDATED',
    DOCUMENT_SAVED = 'DOCUMENT_SAVED',
}

export enum CLIENT_EVENTS {
    JOIN_CONTRACT_ROOM = 'JOIN_CONTRACT_ROOM',
    LEAVE_CONTRACT_ROOM = 'LEAVE_CONTRACT_ROOM',
    JOIN_ORG_ROOM = 'JOIN_ORG_ROOM',
    LEAVE_ORG_ROOM = 'LEAVE_ORG_ROOM,'
}

export interface SERVER_TO_CLIENT_EVENTS {
    noArg: () => void; //example
    basicEmit: (a: number, b: string, c: string) => void; //example
    withAck: (d: string, callback: (e: number) => void) => void; //example
    [SOCKET_EVENTS.REPO_FILES_STATUS]: (data: { [key: string]: number }) => void;
    [SOCKET_EVENTS.REPO_TAG_EXTRACTED]: (data: { entities: ExtractedEntity[], fileId: string, docTypeId: string, lastElement: boolean }) => void;
    [SOCKET_EVENTS.CONTRACT_TAG_MAPPING]: (data: { mappings: IGetContractTagMappings[], documentInSync: boolean, singleElement: boolean }) => void;
    [SOCKET_EVENTS.REFRESH_IN_PROGRESS]: (data: { username: string }) => void;
    [SOCKET_EVENTS.REFRESH_DONE]: () => void;
    [SOCKET_EVENTS.SUMMARY_GENERATION_COMPLETED]: (data: { summary: string }) => void;
    [SOCKET_EVENTS.CHECKLIST_ITEMS_EXTRACTED]: (data: { checklists: IGetContractChecklist[], lastElement?: boolean, singleElement: boolean }) => void;
    [SOCKET_EVENTS.SIGNATORIES_UPDATED]: () => void;
    [SOCKET_EVENTS.DOCUMENT_SAVED]: (data: { updatedAt: Date }) => void;
}


export interface CLIENT_TO_SERVER_EVENTS {
    [CLIENT_EVENTS.JOIN_CONTRACT_ROOM]: (data: string) => void;
    [CLIENT_EVENTS.LEAVE_CONTRACT_ROOM]: (data: string) => void;
    [CLIENT_EVENTS.JOIN_ORG_ROOM]: (data: string) => void;
    [CLIENT_EVENTS.LEAVE_ORG_ROOM]: (data: string) => void;
}

@Injectable({ providedIn: 'root' })
export class SocketService {
    private socket !: Socket<SERVER_TO_CLIENT_EVENTS, CLIENT_TO_SERVER_EVENTS>;
    private socketSubject !: BehaviorSubject<Socket<SERVER_TO_CLIENT_EVENTS, CLIENT_TO_SERVER_EVENTS>>;

    constructor(private authenticationService: AuthenticationService) {
        this.authenticationService.currentUser.subscribe(currentUser => {
            if (currentUser) {
                this.socket = io(environment.ioSocketUrl, {
                    transports: ['websocket'],
                    query: { token: localStorage.getItem('token') as string },
                    reconnectionAttempts: 3,
                    reconnection: true
                });

                this.socketSubject = new BehaviorSubject(this.socket)


            } else {
                if (this.socket) {
                    this.socket.disconnect();
                }
            }

        });
    }

    getSocket() {
        return this.socket
    }

    getSocketId() {
        if (this.socket && this.socket.connected) {
            return this.socket.id
        } else {
            return null
        }
    }

    on<T>(event: string): Observable<T[]> {
        return new Observable(observer => {
            this.socket.on(event as keyof SERVER_TO_CLIENT_EVENTS, (...result: T[]) => {
                observer.next(result);
            });
        });
    }
}
