import * as echarts from 'echarts';

export enum GAUGE_COLORS {
    RED = '#FF4500',
    YELLOW = '#FFD700',
    GREEN = '#32CD32'
}

export function setGuageChart(value: number, colors: string[], maxValue?: number): echarts.EChartsOption {
    return {
        series: [
            {
                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                center: ['50%', '70%'],
                radius: '100%',
                min: 0,
                max: maxValue ?? 100,
                splitNumber: 10,
                axisLine: {
                    lineStyle: {
                        width: 5,
                        color: [
                            [0.33, colors[0]],
                            [0.66, colors[1]],
                            [1, colors[2]]
                        ]
                    }
                },
                pointer: {
                    width: 5,
                    length: '70%',
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                title: {
                    show: false
                },
                detail: {
                    show: false
                },
                data: [
                    {
                        value
                    }
                ],
                animationDuration: 1500, // Initial display duration
                animationDurationUpdate: 1500 // Duration for updates
            }
        ]
    }
}